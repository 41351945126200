<script>
import api from '@/command/api'
import TemplateHeaderTable from '../../../components/TemplateHeaderTable'
import { getAction } from '@/command/netTool'
import orderConfig from '@/orderConfig'
import { shipBusStateList } from '@/utils/textFile'
import apiTool from '@/command/apiTool'
import { eatDrinkMixin } from '@/mixins/orderProcessing/eatDrinkMixin'

export default {
  name: 'eatDrink',
  data() {
    return {
      ...api.command.getState(),
    }
  },
  mixins: [eatDrinkMixin],
  mounted() {
    this.getInit()
  },
  methods: {
    getInit() {
      api.command.getList.call(this, {
        url: '/bus/order/page',
        current: 1,
      })
    },
    getHeader() {
      return [
        {
          name: '单号',
          type: 'input',
          key: 'orderSn',
        },
         {
          name: '手机号',
          type: 'input',
          key: 'buyPhone',
        },
      ]
    },
    // 获取选中订单详情
    getDetailData(id) {
      return new Promise((resolve, reject) => {
        getAction(`/api/bus/order/detail?id=${id}`)
          .then((result) => {
            resolve(result.data)
          })
          .catch((e) => reject && reject(e))
      })
    },
    // 退款事件
    // onRefund({ records, item }) {
    //   let that = this
    //   // type手动传参 告诉当前订单类型
    //   orderConfig.refund({
    //     data: records,
    //     params: { data: { ...records, type: 'eatDrink' } },
    //     executeFun: [
    //       // 基础
    //       orderConfig.base,
    //       // 优惠券
    //       orderConfig.coupons,
    //       // 售后
    //       orderConfig.afterProcessing,
    //       // 记录
    //       orderConfig.record,
    //     ],
    //     success({ data, setHidden }) {
    //       api.order.refund
    //         .call(that, {
    //           url: '/agr/order/refund',
    //           params: { ...item, orderId: item.orderId, ...data },
    //         })
    //         .then(setHidden)
    //     },
    //   })
    // },
    onClose(data) {
      orderConfig.close({
        data,
        params: {
          data,
        },
        executeFun: [
          // 表格数据
          orderConfig.table,
          // 基础信息
          orderConfig.base,
          // 记录
          orderConfig.record,
        ],
        success: ({ setHidden }) => {
          api.order.close
            .call(this, {
              url: '/common/order/close',
              params: { id: data.id },
            })
            .then(setHidden)
        },
      })
    },
    getColumns() {
      return [
        {
          dataIndex: 'startStationName',
          title: '出发终点站',
          align: 'left',
          customRender: (text, records) => {
            return `${records.startStationName}-${records.endStationName}`
          },
          onExport(data,records){
            return `${records.startStationName}-${records.endStationName}`
          }
        },
 

        {
          dataIndex: 'busTime',
          title: '出发日期',
          align: 'left',
        },
        {
          dataIndex: 'productQuantity',
          title: '数量',
          align: 'left',
        },
        {
          dataIndex: 'payAmount',
          title: '实付金额',
          align: 'left',
        },
        {
          dataIndex: 'buyName',
          title: '购买人',
          align: 'left',
        },
        {
          dataIndex: 'buyPhone',
          title: '手机号',
          align: 'left',
        },
        {
          dataIndex: 'createTime',
          title: '下单时间',
          align: 'left',
        },
        {
          dataIndex: 'status',
          title: '订单状态',
          type: 'badge',
          filterMultiple: false,
          filters: shipBusStateList,
          render(data, records) {
            return {
              // showDot: true,
              name: (shipBusStateList.find((e) => e.value == data) || { text: '' }).text,
              color: 'transparent',
            }
          },
           onExport(data,e){
             return (shipBusStateList.find((e) => e.value == data) || { text: '' }).text
          }
        },
        {
          dataIndex: 'action',
          title: '操作',
          type: 'buttonGroup',
          align: 'center',
          width: '10%',
          customRender: (text, records) =>{
            return (
              <div class="actionBox">
                <a onClick={()=>this.onRowSelect(records.id)} >查看</a>
              </div>
            )
          },
        },
      ]
    },
    getColumnsButtonData({ parent, item, index, childrenId } = {}) {
      let arr = [
        {
          name: '查看订单',
          display: true,
          onClick: () => this.onRowSelectEatDrink(parent.id, item.id),
        }, 
      ]
      if (process.env.NODE_ENV === 'development') {
        return arr.filter((e) => e.display)
      } else {
        const buttonName = apiTool.getButtonName()
        return arr.filter((e) => e.display && buttonName.some((el) => e.name.indexOf(el) !== -1))
      }
    },
    renderColumnButton({ orderItem, item }) {
      return (
        <a
          style={{ marginRight: '10px' }}
          onClick={(event) => {
            event.preventDefault()
            event.stopPropagation()
            item.onClick(orderItem)
          }}
        >
          {item.name}
        </a>
      )
    },
    // 获取选中订单发票详情
    getInvoiceDetailData(id) {
      return new Promise((resolve, reject) => {
        getAction(`/farmOrderInvoice/queryDetailByOrderId?orderId=${id}`)
          .then((result) => {
            resolve(result.data)
          })
          .catch((e) => reject && reject(e))
      })
    },
    
    // 批量删除
    onBatchDelete(keys) {
      return api.order.batchClose.call(this, {
        url: '/common/order/closeBatch',
        params: { idList: keys.map((e) => e.id) },
      })
    },
    getButton() {
      return [
        // {
        //   viewType: 'menu',
        //   name: '更多操作',
        //   isBatch: true,
        //   children: [
        //     {
        //       name: '批量关闭',
        //       onClick: this.onBatchDelete,
        //     },
        //   ],
        // },
      ]
    },

    onRowSelect(orderId,  ) {
      const ext = [
        // 表格
        orderConfig.ticketBase,
        // // 退款信息
        // orderConfig.retuenInfo,
        // 可选择表格
        orderConfig.ticketMoney,
        // 基本信息
        orderConfig.ticketInfo,
        orderConfig.ticketCustom,
        // 记录
        orderConfig.ticketRecord,
      ]

      this.getDetailData(orderId,  ).then((e) => {
        
        orderConfig.open({
          data: e,
          params: { data: e,type:2},
          executeFun: ext,
          onHidden: () => {
            this.getInit()
          },
          foot: ({ close, submit }) => {
            return [
              {
                name: '取消',
                onClick: close,
              },
            ]
          },
        })
      })
    },
  },
  render() {
    return (
      <TemplateHeaderTable
        headerData={this.getHeader()}
        tableColumns={this.getColumns()}
        button={this.getButton()}
        records={this.records}
      />
    )
  },
}
</script>
<style lang="less" scoped>
.product-order-item {
  display: flex;
  align-items: flex-start;
  &:not(:last-child) {
    margin-bottom: 30px;
  }
  img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    border-radius: 5px;
    object-fit: cover;
  }
}
.product-number {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  div {
    height: 30px;
    &:not(:last-child) {
      margin-bottom: 30px;
    }
  }
}
.actionBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
